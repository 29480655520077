html,
body {
    scroll-behavior: smooth;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: 'GucciSans-Book';
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.notauth {
    position: relative;
    top: 50%;
    width: 300px;
    margin: auto;
    font-size: 23px;
    font-family: 'GucciSans-Book';
}

.error {
    position: relative;
    top: calc(50% - 40px);
    width: 300px;
    margin: auto;
    font-size: 30px;
    font-family: 'GucciSans-Bold';
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}


@font-face {
    font-family: 'GucciSans-Bold';
    src: url('./resources/fonts/GucciSans-Bold.otf');
    /* IE9 Compat Modes */
}

@font-face {
    font-family: 'GucciSans-Book';
    src: url('./resources/fonts/GucciSans-Book.otf');
    /* IE9 Compat Modes */
}

.ml-10 {
    margin-left: 10px;
}

.center-align {
    align-self: center;
}

.gucci_text {
    font-family: 'GucciSans-Book';
}

.bold {
    font-family: 'GucciSans-Bold' !important;
}

.flex {
    display: flex;
}

.link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
    font-family: 'GucciSans-Book';
    color: black;
    font-style: italic;
    margin-bottom: 5px;
    margin-right: 10px;
}

.pointer {
    cursor: pointer;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 400px;
}

.ellipsis_card {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 360px;
}

.ellipsis_detail {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90%;
}

.vertical-align {
    align-items: center;
}

.homepage {
    background-color: #f0efef;
    width: 100%;
    height: 100vh;
}

.mt-50 {
    margin-top: 50px !important;
}

.mr-50 {
    margin-right: 50px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-2 {
    margin-right: 5px !important;
}

.mr-5 {
    margin-right: 5px !important;
}

.back {
    font-family: "GucciSans-Book";
}

.btn {
    white-space: nowrap !important;
    display: inline-block !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    text-align: center !important;
    text-decoration: none !important;
    vertical-align: middle !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    background-color: transparent;
    border: 1px solid transparent !important;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
    background: linen;
    font-size: 16px !important;
    padding: 5px 10px !important;
    border-radius: 10px !important;
    background-color: #5ad6fc;
    color: white !important;
    margin: 0 0px 0 0 !important;
    font-family: "GucciSans-Book" !important;
}

.iziToast-wrapper-bottomCenter {
    text-align: right !important;
}

.iziToast-body {
    padding-right: 33px !important;
}

/* NAVBAR */

.navbar {
    position: fixed;
    top: 0;
    z-index: 99;
    background-color: #000;
    height: 40px;
    width: 100%;
    padding: 10px 0;
    text-align: -webkit-center;
}

.navbar_container {
    justify-content: center;
    width: 1200px;
}

#navbar_logo_container {
    position: absolute;
    top: 10px;
    min-width: 130px;
}

#navbar_gucci_logo {
    background-image: url("./resources/img/gucci_logo_bianco_2.png");
    height: 25px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 5px;
}

#navbar_subtitle {
    color: #fff;
    text-transform: uppercase;
    font-family: "GucciSans-Bold";
    font-size: 12px;
}

#user_icon_container {
    margin-left: auto;
    margin-top: 5px;
}

.loader-cont {
    margin-top: 100px;
    min-height: 30vh;
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid rgb(5, 5, 5);
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

/* CENTER COMPONENT */

.center_component_container {
    overflow-y: auto;
    height: 100vh;
    scroll-behavior: smooth;
}

.center_component_container::-webkit-scrollbar-track {
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin-top: 100px;
    margin-bottom: 30px;
}

.center_component_container::-webkit-scrollbar {
    width: 8px;
}

.center_component_container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #000000 !important;
    border: 1px solid #000;
}

.questions-container::-webkit-scrollbar-track {
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin-top: 100px;
    margin-bottom: 30px;
}

.questions-container::-webkit-scrollbar {
    width: 8px;
}

.questions-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #000000 !important;
    border: 1px solid #000;
}

.center_component {
    padding: 70px 10px 10px 10px;
    width: 1200px;
}

.border_left {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.border_right {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.calendar_button {
    padding: 10px 5px;
    border: 2px solid #000;
    font-family: "GucciSans-Book";
    text-transform: uppercase;
    width: 150px;
    text-align: -webkit-center;
    background-color: #f0efef;
}

.calendar_button_selected {
    padding: 10px 5px;
    background-color: #000;
    font-family: "GucciSans-Book";
    color: #fff;
    text-transform: uppercase;
    width: 150px;
    text-align: -webkit-center;
    border: 2px solid #000;
}

/* CALENDAR */

.month_name_calendar_container {
    text-align: left;
    margin-right: auto;
}

.courses_number_calendar_container {
    text-align: right;
    margin-left: auto;
}

.month_name {
    font-family: "GucciSans-Bold";
    font-size: 20px;
    text-transform: uppercase;
}

.year {
    font-family: "GucciSans-Book";
    font-size: 16px;
    text-transform: uppercase;
}

#left_arrow_calendar {
    margin-left: 17px;
    margin-right: 20px;
}

#right_arrow_calendar {
    margin-left: 17px;
    margin-right: 20px;
}

.rbc-row-segment {
    padding: 0 1px 1px 1px;
    display: none;
}

.calendar_card {
    width: fit-content;
}

.rbc-calendar {

    margin-top: 50px;
}

.rbc-month-view {
    border: 0px !important;
    width: fit-content;
    max-width: 560px;
}

.rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: none !important;
    flex-basis: none !important;
    overflow: hidden;
    height: 65px !important;
}

.rbc-header+.rbc-header {
    border-left: 0px !important;
}

.rbc-header {
    border-bottom: 0px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    font-family: "GucciSans-Bold" !important;
    text-transform: uppercase;
}

.rbc-toolbar .rbc-toolbar-label {
    text-align: left !important;
    font-family: "GucciSans-Bold";
    text-transform: uppercase;
    font-size: 20px;
    margin-right: auto !important;
    padding: 0 20px !important;
}

.rbc-btn-group {
    white-space: nowrap;
}

.rbc-off-range-bg {
    background: none !important;
}

.rbc-month-row+.rbc-month-row {
    border: 0px !important;
}

.rbc-month-view {
    width: fit-content !important;
}

.rbc-month-header {
    width: fit-content;
}

.rbc-day-bg+.rbc-day-bg {
    border: 0px !important;
}

.rbc-button-link {
    color: inherit;
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    -webkit-user-select: text;
    user-select: text;
    height: 45px;
    width: 45px;
    font-family: "GucciSans-Book";
    font-size: 16px;
}

.rbc-today {
    background-color: #f0efef !important;
}

.rbc-date-cell {
    margin: 15px;
    text-align: -webkit-center !important;
    max-width: 70px !important;
    padding: 0px !important;
    cursor: context-menu !important;
}

.rbc-date-cell.rbc-now {
    font-family: "GucciSans-Bold" !important;
    background-color: #f0efef !important;
    border-radius: 50%;
    color: #000000 !important;
    outline-offset: 3px;
    outline: 2px solid #000;
    max-width: 45px !important;
    margin-right: 18px;
    margin-left: 18px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    position: relative;
}

.rbc-date-cell.rbc-now::before {

    outline-offset: 3px;
    outline: 2px solid #000;
}

/* EVENT LIST CALENDAR */

.calendar_list_container {
    width: 520px;
    margin-top: 20px;
}

.calendar_list {
    overflow-y: auto;
    padding-right: 10px;
}

.calendar_list::-webkit-scrollbar-track {
    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin: 10px 0;
}

.calendar_list::-webkit-scrollbar {
    width: 8px;
}

.calendar_list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #000000 !important;
    border: 1px solid #000;
}

#date_string_calendar_list {
    font-size: 16px;
    font-family: "GucciSans-Bold";
    text-transform: uppercase;
    text-align: -webkit-left;
}

.rmsc .dropdown-container:focus-within {
    box-shadow: none !important;
    border-color: black !important;
}

.rmsc .dropdown-container {
    position: relative;
    outline: 0;
    background-color: var(--rmsc-bg);
    border-bottom: 1px solid var(--rmsc-border);
    border-radius: 0;
}

.rmsc .item-renderer {
    align-items: center !important;
}

.rendered {
    width: 75px;
    height: 16px;
    background: #00000000;
    position: absolute;
    margin-left: 3px;
    color: #8787878c;
    font-size: 11px;
    padding: 1px;
    text-align-last: center;
    top: 0px;
    right: -2px;
}


.suggested_by {
    background: #04040494;
    color: white;
    font-family: 'GucciSans-Book';
    margin-top: -22px;
    padding: 2px;
    font-size: 15px;
}

.month_name_shared {
    font-family: 'GucciSans-Bold';
    text-transform: capitalize;
    width: 400px;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 15px;
    font-size: 18px;
}

.event_list_icon {
    background-color: #000;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-left: auto;
    min-width: 45px;
    min-height: 45px;
}

.calendar_list_item {
    border-left: 3px solid;
    text-align: -webkit-left;
    padding-left: 30px;
    margin-bottom: 10px;
}

.favorite_icon {
    margin-top: 11px;
    margin-left: 10px;
}

.course_type {
    font-family: "GucciSans-Bold";
    text-transform: uppercase;
    font-size: 16px;
    word-break: break-word;
}

.course_type_title {
    font-family: "GucciSans-Bold";
    text-transform: uppercase;
    font-size: 19px;
}

.course_type_dim_s {
    max-width: 350px
}

.course_type_dim_sm {
    max-width: 400px
}

.course_type_dim_m {
    max-width: 520px
}

.topic_type {
    font-family: "GucciSans-Book";
    font-size: 16px;
}

.loadMore_button {
    width: 170px;
    border: 1px solid #bababa;
    padding-top: 6PX;
    background: white;
    font-family: 'GucciSans-Bold';
    border-radius: 2px;
    height: 17px;
    font-size: 10px;
    margin-top: 20px;
    text-transform: uppercase;
}

.dialog_close_button {
    background-color: #000 !important;
    color: white !important;
}

/* EVENTS LIST CARD */

.event_cards_list::-webkit-scrollbar-track {

    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin: 40px 0;
}

.event_cards_list::-webkit-scrollbar {
    width: 8px;
}

.event_cards_list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #000000 !important;
    border: 1px solid #000;
}

.event_cards_container {
    margin-top: 20px;
}

.event_card_list {
    background-color: #fff;
    width: 400px;
    height: auto;
    border-radius: 20px;
    margin-bottom: 20px;
    box-shadow: 2px 2px #6f6f6f6b;
}

.card_image_container {
    background-size: cover;
    height: 150px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

#back_wishlist_container {
    width: 400px;
    text-align: -webkit-center;
    text-align-last: left;
    margin-bottom: 10px;
}

.card_image_wishlist {
    padding-top: 10px;
    padding-right: 10px;
}

.favorite_icon_card {
    margin-top: 11px;
}

.card_text_container {
    padding: 10px 22px;
    text-align: left;
}

.card_column {
    width: 50%;
}

.margin-icon-card {
    margin-right: 10px;
}

/* EVENT DETAIL */
.label_text {
    font-style: italic;
    font-size: 15px;
    font-family: 'GucciSans-Book';
    align-self: center;
    width: 90px;
    min-width: 90px;
}

.event_detail_container {
    max-width: 560px;
    text-align: -webkit-left;
    padding-bottom: 70px;
}

.event_detail_image {
    margin-top: 10px;
    height: 300px;
    max-width: 560px;
    background-size: cover;
    border-radius: 20px;
    background-position: center;
}

.fav_button_container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 20px;
    text-transform: uppercase;
    font-family: "GucciSans-Bold";
    cursor: pointer;
}

.sugg_button_container {
    display: flex;
    left: 0;
    right: 0;
    bottom: 50px;
    margin-top: 3px;
    width: 130px;
    background-color: #000;
    color: #fff;
    padding: 6px 20px;
    border-radius: 20px;
    text-transform: uppercase;
    font-family: "GucciSans-Bold";
    cursor: pointer;
    float: right;
}

.calendar_button_container {
    display: flex;
    left: 0;
    right: 0;
    bottom: 50px;
    margin-top: 3px;
    width: 310px;
    background-color: #000;
    color: #fff;
    padding: 6px 20px;
    border-radius: 20px;
    text-transform: uppercase;
    font-family: "GucciSans-Bold";
    cursor: pointer;
    float: right;
}

.copy_button_container {
    display: flex;
    margin-top: 23px;
    width: fit-content;
    background-color: #000;
    color: #fff;
    padding: 6px 20px;
    border-radius: 20px;
    text-transform: uppercase;
    font-family: "GucciSans-Bold";
    cursor: pointer;
    margin: 20px auto;
}

.util_container>div {
    background: #f0efef !important;
    padding: 0 !important
}

div.util_container>div>div>div>p {
    color: white !important;
}

.share_ico {
    align-self: center;
    margin-right: 30px;
}

.cal_ico {
    align-self: center;
    margin-right: 60px;
}

.follower_ico {
    align-self: center;
    margin-right: 10px;
}

.event_day {
    background-color: #000;
    border-radius: 50%;
    color: #fff !important;
    cursor: pointer !important;
}

.rbc-current>.selected_day_calendar,
.selected_day_calendar {
    outline-offset: -2px !important;
    outline: 4px solid #fff !important;
    border-radius: 50%;
    background-color: white !important;
    color: black !important;
}

.materials_container {
    display: flex;
    flex-wrap: wrap;
}

/*
.event_day {
    background-color: #000 !important;
    border-radius: 50%;
    color: #fff !important;
    cursor: pointer !important;
}
*/


.suggest {
    background: #000000;
    width: 20%;
    color: white;
    border-radius: 5px;
    padding: 2px;
    float: right;
    margin-top: 13px;
    BOX-SHADOW: 1px 2px 3px #00000082;
    margin-right: 4px;
    cursor: pointer;
    font-family: 'GucciSans-Book';
    text-align: -webkit-center;
}

.track-container {
    width: 100%;
    height: 100vh;
    background: #f0efef;
}

.track-message {
    color: rgb(0, 0, 0);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
    font-size: 20px;
    font-family: 'GucciSans-Bold';
}

.survey-form {
    max-width: 600px;
    text-align: left;
}

.survey-send-button {
    background: rgb(0, 0, 0);
    width: fit-content;
    color: rgb(255, 255, 255);
    border-radius: 25px;
    padding: 5px 50px;
    font-family: 'GucciSans-Bold';
    font-size: 16px;
    cursor: pointer;
}

.questions-container {
    overflow: auto;
    height: 60vh;
    padding-bottom: 50px;

}

.survey-title {
    color: rgb(0, 0, 0);
    width: 85%;
    text-align: left;
    font-size: 23px;
    font-family: 'GucciSans-Bold';
    text-transform: capitalize;
}

.survey-subtitle {
    color: rgb(0, 0, 0);
    width: 85%;
    text-align: left;
    font-size: 16px;
    margin-top: 2px;
    font-family: 'GucciSans-Book';
    text-transform: capitalize;
}

.required_flag {
    color: red;
    font-family: 'GucciSans-Book';
}

.survey-input {
    width: calc(100% - 40px);
    font-family: "GucciSans-Book";
    background: #f0efef;
    border: none;
    border-bottom: 1px solid;
    height: 35px;
    resize: none;
    align-content: flex-end;
    font-size: 16px;
}

.survey-input:focus {
    outline: none;
}

.survey-container {
    width: 92%;
    height: 100vh;
    background-color: #f0efef;
    text-align: -webkit-center;
    padding: 6% 4%;
    font-family: 'GucciSans-Bold';

}


.css-hlj6pa-MuiDialogActions-root>:not(:first-of-type) {
    background-color: black;
    color: white;
}

.loader1 {
    width: 48px;
    height: 48px;
    margin: auto;
    position: relative;
    z-index: 1000;
}

.loader1:before {
    content: '';
    width: 40px;
    height: 5px;
    background: #686868;
    position: absolute;
    top: 60px;
    left: 4px;
    border-radius: 50%;
    animation: shadow324 0.5s linear infinite;
    background-size: cover;
    background-repeat: no-repeat;
}

.loader1:after {
    content: '';
    width: 50px;
    height: 50px;
    background-image: url('./resources/img/favicon2.png');
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    animation: jump 2s linear infinite;
    background-size: cover;
    background-repeat: no-repeat;
}

.follower_list {
    max-height: 45vh;
    overflow-x: auto;
}

.follower_list::-webkit-scrollbar-track {

    border-radius: 10px;
    padding: 2px 0;
    background-color: #dfdfdf;
    margin-top: 10px;
    margin-bottom: 10px;
}

.follower_list::-webkit-scrollbar {
    width: 8px;
}

.follower_list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #000000 !important;
    border: 1px solid #000;
}

@keyframes jump {

    6% {
        transform: translateY(9px) rotate(22.5deg);
    }

    12% {
        transform: translateY(18px) scale(1, .9) rotate(45deg);
    }

    19% {
        transform: translateY(9px) rotate(67.5deg);
    }

    25% {
        transform: translateY(0) rotate(90deg);
    }

    31% {
        transform: translateY(9px) rotate(115deg);
    }

    38% {
        transform: translateY(18px) scale(1, .9) rotate(135deg);
    }

    44% {
        transform: translateY(9px) rotate(155deg);
    }

    50% {
        transform: translateY(0) rotate(180deg);
    }

    56% {
        transform: translateY(9px) rotate(195deg);
    }

    63% {
        transform: translateY(18px) scale(1, .9) rotate(230deg);
    }

    69% {
        transform: translateY(9px) rotate(255deg);
    }

    75% {
        transform: translateY(0) rotate(270deg);
    }

    81% {
        transform: translateY(9px) rotate(295deg);
    }

    88% {
        transform: translateY(18px) scale(1, .9) rotate(315deg);
    }

    94% {
        transform: translateY(9px) rotate(345deg);
    }

    100% {
        transform: translateY(0) rotate(360deg);
    }
}

@keyframes shadow324 {

    0%,
    100% {
        transform: scale(1.2, 1);
    }

    50% {
        transform: scale(1, 1);
    }
}

.filter_container {
    border: 1px solid black;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    align-self: center;
    margin-left: 20px;
    background: black;
}

.mobile_filter_container {
    margin-top: 50px;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 4;
    padding: 20px 10px 10px 10px;
    max-width: 650px;
    text-align: -webkit-center;
}

.close_filters {
    position: absolute;
    right: 30px;
    top: 19px;
    z-index: 5;
}

.filter_block {
    width: 80%;
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
}

.multiSelectContainer li:hover {
    background-color: #000000 !important;
    color: white;
}

input[type='checkbox'] {
    -o-appearance: none;
    appearance: none;
    width: 14px !important;
    height: 14px !important;
    margin: 1px 8px;
    /* outline: 2px solid rgb(88, 88, 88);*/
    box-shadow: none;
    font-size: 10px;
    text-align: center;
    line-height: 12px;
    border: 1px solid black;
    padding: 0px;
    border-radius: 0px;
}

input[type=checkbox]:checked:after {
    content: "✔" !important;
    width: 14px !important;
    height: 14px !important;
}

.multiSelectContainer li {
    font-size: 14px;
}

input[type='checkbox']:checked:after {
    color: rgb(59, 59, 59);
    content: '✔';
}

input[type='checkbox']:hover {
    color: rgb(255, 255, 255);
    content: '✔';
}

@keyframes bottom_to_top {
    0% {
        bottom: -60vh;
    }

    100% {
        bottom: 0;
    }
}

@media (max-width: 1200px) {

    .navbar_container {
        width: calc(100% - 20px);
    }

    .center_component {
        width: calc(100% - 20px);
    }

    .rbc-header {
        min-width: inherit !important;
    }

    .rbc-month-view {
        width: 100% !important;
    }

    .rbc-row {
        width: 100%;
    }

    .rbc-date-cell {
        width: auto !important;
    }


}

@media (max-height: 900px) {

    .event_detail_container {
        max-width: 560px;
        text-align: -webkit-left;
        padding-bottom: 140px;
    }

    .filterbox {
        animation: bottom_to_top 1s forwards;
    }

    .multiSelectContainer ul {
        max-height: 145px !important;
    }
}

@media (max-width: 560px) {

    .course_type_dim_sm {
        max-width: 230px;
    }

    .course_type_dim_m {
        max-width: 310px;
    }

    .course_type_dim_s {
        max-width: 280px;
    }



    .calendar_list_container {
        width: 100%;
    }

    .rbc-date-cell.rbc-now {
        margin-left: 7px;
        margin-right: 0px;
    }

    .back {
        font-size: 18px;
        margin: 10px 0;
    }

    .event_cards_container {
        margin-top: 20px;
    }

    .rbc-calendar {
        margin-top: 20px;
    }

    .rbc-toolbar {
        margin-bottom: 50px;
    }
}


@media (max-width: 500px) {

    /* CALENDAR */
    .calendar_card {
        width: 100%;
    }

    .rbc-date-cell {
        margin: 10px 0px;
    }

    .rbc-date-cell.rbc-now {
        max-width: 45px !important;
    }


    .calendar_list_container {
        width: 100% !important;
        margin-bottom: 100px;
    }

    /* CARD */

    .event_card_list {
        width: 95%;
    }

    #back_wishlist_container {
        width: 95%;
    }
}

@media (max-width: 400px) {
    .month_name_shared {
        width: 94% !important;
    }

    .rbc-date-cell.rbc-now {
        outline-offset: 1px !important
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {

    body.disable-scroll {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100vh;
        overflow: hidden;
    }

    .rmsc .options {
        max-height: 20vh !important;
    }

    .event_detail_container {
        padding-top: 20px !important;
        position: relative;
    }

    .navbar {
        position: fixed;
        top: 0;
    }

    .css-zw3mfo-MuiModal-root-MuiDialog-root {
        position: absolute
    }
}